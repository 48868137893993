import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core";
import {toast} from "react-toastify";

const filter = createFilterOptions({
	trim: true,
});

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
		marginTop: 20
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	marginTop: {
		marginTop: 20
	}
}));

const NewTicketModal = ({ modalOpen, onClose, contact }) => {
	const history = useHistory();
	const classes = useStyles();
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const [selectedQueue, setSelectedQueue] = useState("");
	const [whatsapps, setWhatsapps] = useState([]);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchWhatsapps = async () => {
				try {
					const { data: wpps} = await api.get("whatsapp" );
					setWhatsapps(wpps);

					wpps.map((wpp) => {
						if (user.whatsapp?.id) {
							if (user.whatsapp.id === wpp.id) setSelectedWhatsapp(wpp.id);
						} else {
							if (wpp.isDefault) setSelectedWhatsapp(wpp.id);
						}
						return null;
					})

					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchWhatsapps();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen, user]);

	useEffect(() => {
		if (contact) {
			handleSelectOption("", contact);
		}
	}, [contact]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleSaveTicket = async () => {
		const contact = selectedContact.id;
		const queue = selectedQueue;
		const whatsappId = selectedWhatsapp;
		if (!contact) return;
		if (!queue) {
			toast.error("Selecione uma fila válida");
			return;
		}
		if (!whatsappId) {
			toast.error("Selecione uma conexão válida");
			return;
		}
		setLoading(true);
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contact,
				queueId: queue,
				whatsappId,
				userId: user.id,
				status: "open",
			});

			await api.post("/log", {
				log: {
					descricao: `O usuário ${user?.name} abriu um ticket(${ticket.id}), fila(${ticket.queue?.name}), conexão(${ticket.whatsapp?.name}) para o contato ${ticket.contact?.name}`,
					origem: 'Ticket',
					userId: user?.id,
					ticketId: ticket.id,
					contactId: contact,
					queueId: queue,
					whatsappId: whatsappId
				}
			});
			history.push(`/tickets/${ticket.id}`);
			handleClose();
		} catch (err) {
			const error = (err.response?.data?.error);
			if (error === "ERR_OTHER_OPEN_TICKET") {
				const {data: existingTicket} = await api.get("/tickets/contact/" + contact, {
					params: {
						contactId: contact,
						whatsappId
					}
				});
				console.log(existingTicket)
				let msg = `Já existe uma conversa ${existingTicket?.status === "open" ? "em aberto" : existingTicket?.status === "pending" ? "pendente" : ""} para este contato${existingTicket?.status === "bot" ? " sendo atendida pelo bot" : ""}.`
				if (existingTicket.user)
					msg+= ` Para o(a) atendente: ${existingTicket.user.name}.`
				if (existingTicket.queue)
					msg+= ` Na fila: ${existingTicket.queue.name}.`
				toast.error(msg);
			} else {
				toastError(err);
				handleClose();
			}
		}
		setLoading(false);
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} else if (newValue?.name) {
			setNewContact({ name: newValue.name });
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactTicket = contact => {
		setTimeout(() => toast.success("Selecione o contato criado para abrir um ticket"), 500);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${i18n.t("newTicketModal.add")} ${option.name}`;
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};

	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
			></ContactModal>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("newTicketModal.title")} {contact ? `com ${contact.name}` : null}
				</DialogTitle>
				<DialogContent dividers>
					<Autocomplete
						options={options}
						loading={loading}
						style={contact ? {display: "none"} : { width: 300 }}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOption}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOption(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								onKeyPress={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") {
										handleSaveTicket();
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>

					<FormControl fullWidth margin="dense" variant="outlined" className={classes.marginTop}>
						<InputLabel>Fila</InputLabel>
						<Select
							required
							labelWidth={60}
							value={selectedQueue}
							onChange={(e) => setSelectedQueue(e.target.value)}
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left",
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left",
								},
								getContentAnchorEl: null,
							}}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{user.queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl fullWidth margin="dense" variant="outlined" className={classes.marginTop}>
						<InputLabel>Conexão</InputLabel>
						<Select
							required
							labelWidth={60}
							value={selectedWhatsapp}
							onChange={(e) => setSelectedWhatsapp(e.target.value)}
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left",
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left",
								},
								getContentAnchorEl: null,
							}}
						>
							{whatsapps.map((wpp) => (
								<MenuItem key={wpp.id} value={wpp.id}>{wpp.name}</MenuItem>
							))}
						</Select>
					</FormControl>

				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={!selectedContact}
						onClick={() => handleSaveTicket()}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketModal;
